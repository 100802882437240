import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Container from '../../components/container';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Common from '../../components/common';

const Dona = () => (
  <Layout>
    <SEO
      title="Dona"
      description="Puedes ser nuestro patrocinador permanente o donador de ocasión y para
        cualquiera de los dos casos tenemos recibos deducibles de impuestos, de
        igual manera si tienes una empresa de productos o servicio que pueden
        contribuir con nuestra causa también puedes hacerlo."
    />
    <Container>
      <Common />
      <p>
        Gracias por donar tu tiempo de vida y el fruto de tu trabajo para ayudar
        a familias enteras que te necesitan.
      </p>
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_blank"
      >
        <input type="hidden" name="cmd" value="_donations" />
        <input type="hidden" name="business" value="nilocyve@gmail.com" />
        <input type="hidden" name="currency_code" value="MXN" />
        <PayPal
          type="image"
          src="/paypal-donate.png"
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Donar con el botón PayPal"
        />
        <img
          alt=""
          src="https://www.paypal.com/es_MX/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
    </Container>
  </Layout>
);

const PayPal = styled.input`
  width: 300px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
`;

export default Dona;
